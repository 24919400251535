import React, { useContext, useEffect, useState } from "react";

import Header from "../../components/Header";
import AgendaCalendario from "../../componentsv2/AgendaCalendario";

import { ModalAviso } from "../../componentsv2/ModalAviso";
import { FormularioContext } from "../../componentsv2/AgendaCalendario/Context/FormularioContext";

export default function Respiratorio() {
  const exames = ["Frutose", "Lactose", "SIBO"];
  //const horarios = ["06:30", "08:30"];

  const [novosHorarios, setNovosHorarios] = useState([
    "06:30",
    "07:00",
    "08:30",
  ]);

  const diasSemana = [1, 2, 3, 4, 5, 6];
  //const novosDiasSemana = [1, 3, 5, 6];
  const acesso = "RES";

  const [openModalAlerta, setOpenModalAlerta] = useState(false);

  const { unidade, setUnidade } = useContext(FormularioContext);

  useEffect(() => {
    setUnidade(42);
    setOpenModalAlerta(true);
  }, []);

  useEffect(() => {
    if (unidade === 64) {
      setNovosHorarios(["07:00"]);
    } else {
      setNovosHorarios(["06:30", "07:00", "08:30"]);
    }
  }, [unidade]);

  return (
    <>
      <Header titulo="Exame Respiratório" />
      <ModalAviso
        modalText={
          "OBRIGATÓRIO ENVIO DAS RECOMENDAÇÕES/CARDÁPIO PARA REALIZAR O TESTE."
        }
        open={openModalAlerta}
        onClose={setOpenModalAlerta}
      />
      <AgendaCalendario
        agenda="respiratorio"
        unidade={unidade}
        exames={exames}
        horarios={novosHorarios}
        diasSemana={diasSemana}
        acesso={acesso}
      />
    </>
  );
}
